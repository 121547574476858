<template>
  <div class="loading loading--content">
    <div class="loading__body">
      <div class="effect-1 loading__effects"></div>
      <div class="effect-2 loading__effects"></div>
      <div class="effect-3 loading__effects"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Preloader'
}
</script>
