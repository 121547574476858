<template>
  <div>
    <Preloader v-if="loading" />
    <template v-if="teams.length">
      <app-cells position="start">
        <app-filters :filters="filters" v-model="current_filter" @change.native="onFilterChange" />
      </app-cells>
      <div class="card-team">
        <router-link :to="{ name: 'team-detail', params: { id: team.id } }" v-for="team in teams" :key="team.id"
                     class="card-team__item" :class="{ 'card-team__item--admin': team.is_admin }">
          <div class="card-team__logo">
            <img v-if="team.is_admin" src="@/assets/img/team/crown.svg" alt="Card logo">
            <img v-else src="@/assets/img/team/team.svg" alt="Card logo">
          </div>
          <div class="card-team__info">
            <h2 class="card-team__title title title--theme">{{ team.name }}</h2>
            <p v-if="team.desc" class="card-team__desc">{{ team.desc }}</p>
            <div class="card-team__stats">
              <div class="card-team__stat">
                <img src="@/assets/img/team/members.svg" alt="Member icon">
                <span>Кол-во участников: <b>{{ team.members_count }}</b></span>
              </div>
              <div class="card-team__stat">
                <img src="@/assets/img/team/calendar.svg" alt="Calendar icon">
                <span>Дата создания: <b>{{ team.created_at }}</b></span>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </template>
    <div v-else-if="!loading" class="slug">
      <div class="slug__img">
        <img src="@/assets/img/team/team-slug.svg" alt="Slug">
      </div>
      <p class="slug__text">Вы еще не состоите ни в одной команде. Создайте команду сами или примите приглашение от
        кого-то.</p>
    </div>
    <paginate
      v-if="pages > 1"
      v-model="page"
      :page-count="pages"
      :clickHandler="onPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
  </div>
</template>

<script>
import Preloader from '@/components/layout/Preloader'

export default {
  name: 'MyTeams',
  components: {
    Preloader,
  },
  data() {
    return {
      teams: [],
      page: 1,
      pages: 0,
      loading: true,
      filters: [
        { label: 'В моем управлении', value: true },
        { label: 'Я участник', value: false },
        { label: 'Все', value: '' },
      ],
      current_filter: ''
    }
  },
  mounted() {
    this.fetchTeams()
    if (this.$route.query.hash) {
      this.agreeInvite()
    }
  },
  methods: {
    fetchTeams(page = 1, limit = 8, is_head = '') {
      this.$store.dispatch('teams/GET_DATA', { page, limit, is_head })
        .then(response => {
          this.loading = false
          this.teams = response.data.results
          this.pages = response.data.pages
        })
        .catch(() => {
          this.loading = false
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка получения данных'
          })
        })
    },
    onFilterChange() {
      this.fetchTeams(1, 8, this.current_filter)
    },
    onPaginationCallback(page) {
      this.fetchTeams(page)
    },
    agreeInvite() {
      this.$store.dispatch('team/POST_LINK_INVITE', { hash: this.$route.query.hash })
        .then(response => {
          this.$router.push({name: 'team-detail', params: { id: response.data.team_id }})
          this.$notify({
            type: 'success',
            title: 'Внимание!',
            text: 'Вы приняты в команду'
          })
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Вы уже в команде'
          })
        })
    }
  },
}
</script>
